.Thumbnail {
    width: 12.5%;
    padding-top: 12.5%;
    position: relative;
    .padding {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        .img {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 20px;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 5px;
            }
        }
        // border: 1px solid black;

        .info {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            // background-color: rgba(0, 0, 0, 0.5);
            color: white;
            padding: 5px;
            font-size: 11px;
        }
        .rating {
            float: left;
        }
        .color {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 5px;
            border-radius: 100%;
        }
        .colorTag_0 {
            border: 1px solid #aaa;
        }
        .name {
            float: right;
        }
    }
    .cloneId {
        // position: absolute;
        // top: 15px;
        // right: 15px;
        display: inline-block;
        font-size: 10px;
        color: white;
        background-color: black;
        line-height: 15px;
        height: 15px;
        min-width: 15px;
        border-radius: 15px;
        text-align: center;
        margin-left: 10px;
    }
}

@media (max-width: 1200px) {
    .Thumbnail {
        width: 20%;
        padding-top: 20%;
    }
}

@media (max-width: 800px) {
    .Thumbnail {
        width: 50%;
        padding-top: 50%;
    }
}
