.FocusImage {
    position: relative;
    .exifInfo,
    .filename {
        position: absolute;
        bottom: 10px;
        left: 0px;
        padding: 10px 20px;
        height: 30px;
        box-sizing: border-box;
        background-color: rgba($color: #ffffff, $alpha: 0.75);
        font-size: 12px;
        color: black;
        font-weight: bold;
        border-radius: 15px;
        > div {
            display: inline-block;
        }
        .iso {
            width: 80px;
        }
        .shutter {
            width: 75px;
        }
        .aperture {
            width: 50px;
        }
    }

    .filename {
        left: auto;
        right: 0px;
    }
}
