.ThumbnailScroller {
    height: 150px;
    width: 100%;
    position: fixed;
    overflow: hidden;
    bottom: 0;
    left: 0;
    padding-bottom: 20px;
    .scroll-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 17px);
        overflow: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        .image {
            display: inline-block;
            height: 100%;
            border-radius: 10px;
            overflow: hidden;
            margin-right: 20px;
            position: relative;
            // background-color: #ccc;
            // box-sizing: border-box;
            border: 2px solid transparent;

            &:first-child {
                margin-left: 20px;
            }

            .thumbnail {
                display: block;
                height: 100%;
                background-color: #ccc;
            }

            .colorTag {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 10px;
                height: 10px;
                border-radius: 100%;
            }
            .stars {
                position: absolute;
                bottom: 10px;
                left: 10px;
                color: white;
            }

            .compare {
                opacity: 0;
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 10px;
                transition: 0.5s opacity;
                cursor: pointer;
                img {
                    width: 20px;
                }
            }

            &.selected_variant {
                border: 2px solid white;
            }

            &:hover {
                .compare {
                    opacity: 1;
                }
            }
        }
    }
}
