@import 'reset';
@import 'variables';

html,
body {
    padding: 0;
    margin: 0;
    font-family: $font-primary;
    background-color: #333;
    color: white;
}

strong {
    font-weight: bold;
}

.options {
    display: flex;
    flex-direction: row;
    margin: 50px 0;
    .col {
        margin-right: 50px;
    }
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px;
}

.main-container {
    position: absolute;
    top: 50px;
    left: 20px;
    right: 20px;
    bottom: 250px;
    display: flex;
    // background-color: rgba(255, 0, 0, 0.5);
    > div {
        flex: 1;

        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 12px;
            font-weight: bold;
            color: black;
            background-color: white;
            height: 30px;
            line-height: 30px;
            padding: 0 10px;
            border-radius: 15px;
        }
    }
    div.spacer {
        flex: initial;
        width: 50px;
    }
    .FocusImage {
        height: 100%;
        .image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }
}

.PathSelector {
    position: absolute;
    bottom: 200px;
    left: 20px;
    z-index: 100;
}

.colorTag_0 {
    background-color: rgba(0, 0, 0, 0);
}
.colorTag_1 {
    background-color: #be0b0b;
}
.colorTag_2 {
    background-color: #be5b0a;
}
.colorTag_3 {
    background-color: #bfbe0b;
}
.colorTag_4 {
    background-color: #0cbf0c;
}
.colorTag_5 {
    background-color: #0b5ec0;
}
.colorTag_6 {
    background-color: #be0bc0;
}
.colorTag_7 {
    background-color: #5d0bbf;
}

.page_home .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
}

.page_shoot {
    padding-top: 50px;
    .logo {
        position: absolute;
        top: 20px;
        left: 20px;
        height: 25px;
    }

    &.page_shoot__grid {
        .PathSelector {
            bottom: auto;
            left: auto;
            right: 120px;
            top: 20px;
        }
    }
}
