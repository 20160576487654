.PathSelector {
    color: black;
    font-size: 12px;
    .currentPath {
        font-weight: bold;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        padding: 0 15px;
        background-color: white;
        img {
            height: 20px;
            transform: translate(-4px, 5px);
        }
    }
    .pathList {
        display: none;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 10px;
        background-color: white;
        .path {
            font-size: 12px;
            padding: 5px 0;
            margin-left: 15px;
            margin-right: 15px;
            position: relative;
            cursor: pointer;

            &.selected::after {
                content: ' ';
                display: block;
                position: absolute;
                top: 8px;
                left: -11px;
                background-color: black;
                border-radius: 100%;
                width: 5px;
                height: 5px;
            }
        }
    }
    &:hover {
        .pathList {
            display: block;
        }
    }
}
