.FollowLatestButton {
    height: 30px;
    line-height: 30px;
    padding: 0 30px 0 15px;
    border-radius: 15px;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
    color: black;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    &::after {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        content: ' ';
        display: block;
        background-color: #999;
    }
    &.following::after {
        background-color: green;
    }
}
